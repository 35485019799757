
@font-face {
    font-family: loto;
    src: url(../fonts/lato/Lato-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: montserrat;
    src: url(../fonts//montserrat/static/Montserrat-Regular.ttf);
    font-display: swap;
    /* font-weight: bold; */
}
@font-face {
    font-family: montserrat;
    src: url(../fonts//montserrat/static/Montserrat-Bold.ttf);
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: montserrat;
    src: url(../fonts//montserrat/static/Montserrat-Medium.ttf);
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: montserrat;
    src: url(../fonts//montserrat/static/Montserrat-SemiBold.ttf);
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans', sans-serif;
    src: url(../fonts/Work_Sans/static/WorkSans-Regular.ttf);
    font-display: swap;
}
@font-face {
    font-family: 'Work Sans', sans-serif;
    src: url(../fonts/Work_Sans/static/WorkSans-Bold.ttf);
    font-weight: bold;
    font-display: swap;
}
/* @font-face {
    font-family: 'Work Sans', sans-serif;
    src: url(../fonts/Work_Sans/static/WorkSans-ExtraBold.ttf);
    font-weight: bolder;
} */

@font-face {
    font-family: 'Work Sans', sans-serif;
    src: url(../fonts/Work_Sans/static/WorkSans-SemiBold.ttf);
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans', sans-serif;
    src: url(../fonts/Work_Sans/static/WorkSans-Medium.ttf);
    font-weight: medium;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans', sans-serif;
    src: url(../fonts/Work_Sans/static/WorkSans-Light.ttf);
    font-weight: 300;
    font-display: swap;
}


*{
    margin: 0;
    padding: 0;
}
body{
    /* font-family: 'Roboto', sans-serif; */
    font-family: montserrat !important;
    font-size: 14px;
    /* background: #f5f5f5; */
}
.text-loto{
    font-family: loto !important;
}
.text-montserrat{
    font-family: montserrat !important;
}
.text-work-space{
    font-family: work_space !important;
}
.text-np-success{
    color: #00a652 !important;
}
.bg-np-success{
    background-color: #00a652 !important;
}

.text-np-primary{
    color: #01054e !important;
}
.bg-np-primary{
    background-color: #01054e !important;
}
.ad-course-title-lh{
    min-height:70px
}
.border-np-primary{
    border: none;
    height: 1px;
    color: #01054e;
    background-color: #01054e;
    opacity: 1;
}

.border-np-primary2{
    border: 2px solid #01054e !important;
}

.border-np-success{
    border: none;
    height: 1px;
    color: #00a652;
    background-color: #00a652;
    opacity: 1;
}
.border-np-success-ad{
    border: 3px solid #00a652 !important;
}
#content[aria-hidden=true] {
   display:none;
}
.text-np-dark{
    color: #606060 !important;
}

.bg-np-dark{
    background-color: #606060 !important;
}

.partner_testimonial_section{
    background-image: url("../netplus/home/partner_banner_bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center; */
    background-attachment: fixed;
}

.text-np-ligh{
    color: #e6e6e6;
}
.bg-np-ligh{
    background-color: #e6e6e6;
}

.btn-np-success{
    color: #fff !important;
    background-color: #00a652 !important;
    border-color: #00a652 !important;
}

.btn-np-success:hover{
    color: #fff !important;
    background-color: #04994e !important;
    border-color: #04994e !important;
}

.btn-np-outline-success{
    color: #00a652 !important;
    border-color: #00a652 !important;
}


.btn-np-outline-success:hover{
    color: #fff !important;
    background-color: #00a652 !important;
    border: 1px solid #00a652;
}
.btn-np-outline-primary{
    color: #01054e !important;
    border-color: #01054e !important;
}

.btn-np-primary{
    background-color: #01054e !important;
    color: #fff !important;
}
.btn-np-primary:hover{
    background-color: #01054e !important;
    color: #fff !important;
}

.btn-np-outline-primary:hover{
    color: #fff !important;
    background-color: #01054e !important;
    border: 1px solid #01054e;
}
.footer-bg{
    background-color: #000;
}
.footer-icon-fb{
    background-color: #3b5998;
    padding: 6px;
    color: #fff;
}
.footer-icon-fb:hover{
    background-color: #fff;
    color: #3b5998;
}
.footer-icon-tw{
    background-color: #00acee;
    padding: 6px;
    color: #fff;
}
.footer-icon-tw:hover{
    background-color: #fff;
    color: #00acee;
}
.footer-icon-yt{
    background-color: #ff0000;
    padding: 6px;
    color: #fff;
}
.footer-icon-yt:hover{
    background-color: #fff;
    color: #ff0000;
}
.footer-icon-lk{
    background-color: #0077b5;
    padding: 6px;
    color: #fff;
}
.footer-icon-lk:hover{
    background-color: #fff;
    color: #0077b5;
}
.footer-icon-pt{
    background-color: #bd081c;
    padding: 6px;
    color: #fff;
}
.footer-icon-pt:hover{
    background-color: #fff;
    color: #bd081c;
}

.slider-wrapper {
    width: 100%;

    margin: auto;
  }
  .slick-slide {
    /* text-align: center; */
    position: relative;
  }
  .banner-section{
    position: relative;
  }
  .slick-slide:focus {
    outline: none;
  }

  .slick-slide-image {
    max-width: 100%;
    height: auto;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  }
  .slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #01054e !important;
  }

  .slick-slide-title {
    color: #fff;
    padding: 10px;
    position: absolute;
    text-transform: uppercase;
    left: 8vw;
    font-size: 3.5vw;
    font-weight: 900;
    top: 25%;
    width: 80%;
  }
  .slick-slide-title-desc {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 8vw;
    font-size: 1.5vw;
    font-weight: 500;
    top: 37%;
    width: 80%;
  }
  .slick-slide-btn{
    position: absolute;
    left: 9vw;
    font-size: 1vw;
    font-weight: 500;
    top: 55%;
    width: 80%;
  }
  
  .slick-slide-btn button {
    font-size: 1.1vw !important;
    }

.ms-n5 {
    margin-left: -40px;
}
.active_nav_link{
    color: #00a652 !important;
}
/* .sidebar{
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    color: #00a652;
} */

.PhoneInputInput{
    border-radius: 0 !important;
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    border: 1px solid #ced4da;
}

.PhoneInputInput:focus {
    border: 1px solid #00a652 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

/* .lead-table th:nth-child(2), .lead-table td:nth-child(2)
{
  position:sticky;
  left:0px;
  background-color: #fff;
} */

.PhoneInputInput:focus-visible {
    border: 1px solid #00a652 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.PhoneInput--focus {
    border: 0 !important;
}

.apply_now_bg{
    color: #fff;
    background-image: url('../netplus/home/apply_now_bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-attachment: fixed; */
}

.about_college_bg{
    color: #fff;
    background-image: url('../netplus/home/about_college_bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

.career_student_service{
    background-image: url('../netplus/career_service//bg-1.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

.partner_bg{
    background-image: url('../netplus/home/partner_bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-attachment: fixed; */
}
.career_bg{
    background-image: url('../netplus/home/career_bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}
.career_page_bg{
    background-image: url('../netplus/career_service/bg-cert.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}
.career_sub_bg{
    background-image: url('../netplus/home/career_sub_bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-attachment: fixed; */
}

#website-nav .navbar-toggler{
    border: 0;
    background-color: #ffffff;
    border-radius: 0%;
}

#website-nav .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

.testimonial_img_bg{
    background-image: url('../netplus/home/testimonial2.webp');
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center;s */
    background-attachment: fixed;
}

.testimonial_person_img{
    /* border-radius: 50%; */
    /* width: 100px;
    height: 100px; */
    /* border: 2px solid #00a652; */
    float: right;
    margin-right: -30px;
}

.home_courses_bg{
    background-image: url('../netplus/home/courses_sec.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-attachment: fixed; */
}
.courses-custom-field{
    background-color: #fffffff2;
}

.border-np-success2 {
    border: none;
    height: 2px;
    color: #00a652;
    background-color: #00a652;
    opacity: 1;
}

.home-social-link{
    background-color: #00a652;
    color: #fff;
    padding: 6px;
    border-radius: 10%;
}

.website-np .nav-link{
    color: #000;
}
.website-np  .nav-link:hover{
    color: #00a652;
}
.container-np{
    max-width: 90%;
    padding-right: 5%;
    padding-left: 5%;
    margin-right: auto;
    margin-left: auto;
}

.page-banner-text{
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 8vw;
    font-size: 1.5vw;
    font-weight: 500;
    top: 50%;
    width: 80%;

  }
  .page-banner-title{
    color: #fff;
    padding: 10px;
    position: absolute;
    text-transform: uppercase;
    left: 8vw;
    font-size: 3.5vw;
    font-weight: 900;
    top: 35%;
    width: 80%;
  }
  .page-banner-title2{
    color: #fff;
    padding: 10px;
    position: absolute;
    text-transform: uppercase;
    left: 8vw;
    font-size: 3.5vw;
    font-weight: 900;
    top: 35%;
    width: 80%;
  }

.page-intro-title{
    font-size: 45px;
    width: fit-content;
    font-family: 'work_space', sans-serif;
    font-weight: 600;
    line-height: initial;
}
.page-intro-text{
    font-size: 16px;
    font-family: 'montserrat', sans-serif;
    font-weight: 400;
}

.about-section-3{
    background-image: url('../netplus/about/about_section_3.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cojg-section-2{
    background-image: url('../netplus/cojg/cojg_bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.ad_page_bg{
    background-image: url('../netplus/advertisement/bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.fs-np-60{
    font-size: 60px;
}

.fs-np-48{
    font-size: 48px;
}
.fs-np-40{
    font-size: 40px;
    line-height: initial;
}
.fs-np-44{
    font-size: 44px;
    line-height: initial;
}
.fs-np-35{
    font-size: 35px;
    line-height: initial;

}
.fs-np-30{
    font-size: 30px;
    line-height: initial;

}

.fs-np-28{
    font-size: 28px;
    line-height: initial;

}

.fs-np-24{
    font-size: 24px;
}

.fs-np-20{
    font-size: 20px;
}

.fs-np-18{
    font-size: 18px;
}

.fs-np-16{
    font-size: 16px;
}

.fs-np-15{
    font-size: 15px;
}

.fs-np-14{
    font-size: 14px;
}
.fs-np-12{
    font-size: 12px;
}



.width-np-65{
    width: 65%;
}
.about-section-3-height{
    height: 100px;
}

.courses-title-height{
    min-height: 50px;
}

.professors-title-height{
    min-height: 50px;
}

.bg-black{
    background-color: #000;
}
.about_section_5{
    background-image: url('../netplus/about/about_section_5.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;

}

.navbar-nav .active{
    color: #00a652 !important;
}

.form-np .form-control{
    border: 1px solid #fff;
    border-radius: 0;
    height: 45px;
    font-size: 16px;
    font-family: 'montserrat', sans-serif;
    font-weight: 400;
}


.form-np .form-control:focus{
    border: 1px solid #00a652;
    box-shadow: none;
}
.border-bottom-title-ad{
    border-bottom: 2px solid #262626;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: 100px;
    margin: auto;
    
}
.bg-light-blue{
    background-color: #e3e9f5;
}

.bg-light-gray{
    background-color: #202020;
}
.bg-dark-gray{
    background-color: #181818;
}

.bg-np-light{
    background-color: #e4e4e4;
}

.bg-np-light-default{
    background-color: #f8f9fa !important;
}

.bg-np-light2{
    background-color: #ededed !important;
}

.graduation-rate-width{
    width: 70%;
}
.form-control:focus{
    border: 1px solid #00a652;
    box-shadow: none;
}

.form-select:focus{
    border: 1px solid #00a652;
    box-shadow: none;

}
.border-dashed{
    border: dashed;
}

  #courses-section .row [class*='col-'] {
    text-align: center;
    background-color: #ffffff;
    background-clip: content-box;
    min-height: 130px;
    margin-bottom: 15px;
  }
  .better-jobs{
    background-image: url('../netplus/better_jobs/section3_bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-attachment: fixed; */
  }

  .navbar-nav .dropdown-menu {
    position: static;
    border: #dddddd;
    /* border-radius: 0 0 10px 10px; */
    border-radius: 0;
    background: #f0f0f2;
    width: 100%;
    border-top: 2px solid green;
}

.nav-scroller {
    position: static;
    z-index: 2;
    overflow-x: auto;
}

.nav-scroller .nav {
    display: flex;
    position: static;
    align-items: center;
    min-height: 3em;
    height: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}

#website-nav .sub-nav-menu:hover .sub-sub-child-menu .dropdown-menu {
    display: none;
}

#website-nav .sub-nav-menu .dropdown-menu {
    margin-top: 0;
}
#website-nav .sub-child-menu:hover .dropdown-menu {
    display: block;
    visibility: visible;
    margin-top: -10px;
}

#website-nav .sub-child-menu .sub-sub-child-menu:hover .dropdown-menu{
    display: block;
    visibility: visible;
}

#website-nav .dropdown-item.dropdown-submenu.dropdown-toggle {
    text-wrap: auto;
}


.dropdown-item.active, .dropdown-item:active{
    background-color: #00a652;
}

#website-nav .sub-child-menu .dropdown-menu {
    /* display: none; */
    visibility: hidden;
    margin-top: 0;
}
#website-nav .sub-child-menu .dropdown-menu a{
    padding: 5px 10px;
    font-size: 15px;

}
#website-nav .sub-child-menu .dropdown-menu{
    width: 240px;
}
#website-nav .dropdown-item{
    cursor: pointer;
}


.financial_bg_img{
    background-image: url('../netplus/financial_aid/financial_bg_1.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.financial_osap_bg{
    background-image: url('../netplus/financial_aid/financial_bg_2.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.width-fit{
    width: fit-content;
}

#partners-section-images .slick-slide{
   text-align: -webkit-center;
}

/* Style the overlay */
.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.85); */
    /* background-color: #01054e; */
    background: url('../../assets/netplus/about/about_section_3.webp');
    transition: 0.5s;
    overflow-x: hidden;
  }
  
  #search-overlay .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  
  /* Style the open button */
  #search-overlay #search-overlay .open-button {
    margin-top: 30px;
    padding: 10px 30px;
    background: #2196f3;
    color: #fff;
    cursor: pointer;
    border: none;
    border-radius: 10px;
  }
  
  #search-overlay .open-button:hover {
    background: #0b7dda;
  }
  
  /* Style the "Close" button */
  #search-overlay .close-button {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    border: none;
    background: transparent;
    cursor: pointer;
    color: #fff;
  }
  
  /* Style search elements */
  #search-overlay .search-input {
    width: 400px;
    padding: 12px 15px;
    border: none;
    outline: none;
    border-radius: 30px 0px 0px 30px;
  }
  
  #search-overlay .search-button {
    padding: 12px 15px;
    border: none;
    cursor: pointer;
    background: #ffc107;
    border-radius: 0px 30px 30px 0px;
    transition: 0.3s;
  }
  
  #search-overlay .search-button:hover {
    background: #ff9800;
  }
  
  #search-overlay .search-text {
    color: rgba(255, 255, 255, 0.7);
    font-style: italic;
  }
.partners-section .slick-list{
    padding-bottom: 20px;
}
.list-sqaure-style{
    list-style-type: square;
    margin-left: 20px;
}
.list-sqaure-style ::marker{
    color: #00a652;
}

.loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    animation: bg 1s;
  }

  .text-np-justify{
    text-align: justify;
  }

  .loading-wrapper
    {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 142px;
        height: 40px;
        margin: -20px 0 0 -71px;
        background: white;
        filter: contrast(20);
        z-index:10001
        
    }
    .loading-dot
    {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 12px;
        left: 5px;
        filter: blur(4px);
        background: #000;
        border-radius: 50%;
        transform: translateX(0);
        animation: dot 2.8s infinite;
    }
    .loading-dots
    {
        transform: translateX(0);
        margin-top: 12px;
        margin-left: 31px;
        animation: dots 2.8s infinite;
    }
    .loading-wrapper span
    {
        display: block;
        float: left;
        width: 16px;
        height: 16px;
        margin-left: 16px;
        filter: blur(4px);
        background: #000;
        border-radius: 50%;
    }

    .w80px{
        width: 80px;
    }
    .w50px{
        width: 50px;
    }
    .w10px{
        width: 10px;
    }

    @keyframes dot
    {
    50%
        {
        transform: translateX(96px);
        }
    }
    @keyframes dots
    {
    50%
        {
        transform: translateX(-31px);
        }
    }

.pagination {
    margin-top: 45px;
    /* display: flex; */
    /* list-style: none; */
    text-decoration: none;
    /* outline: none; */
    
    }
    
    .pagination>.active>a {
    background-color: #00a652;
    color: #fff;
    text-decoration: none;

    }
    
    .pagination>li>a {
    border: 1px solid #00a652;
    padding: 5px 10px;
    /* outline: none; */
    cursor: pointer;
    text-decoration: none;

    }
    
    .pagination>li>a, .pagination>li>span {
    color: #00a652;
    background-color: rgb(255, 255, 255);
    }

    .social-footer a:hover{
        color: #00a652;
    }
    .partners-section .slick-slide-image{
        width: 80%;
    }
.fc-day-number {
    font-size: 1.5em;
    color: #01054e;
    }
    
    /* override day title color and size */
    .fc-day-header {
    font-size: 1.5em;
    color: #00a652
    }
#website-calender .fc-toolbar{
    padding: 10px;
    background-color: #00a652;
    color: #fff;
}

#website-calender th{
    color : #fff;
    background-color: #00a652; 
    padding: 1px 0;

}

#website-calender th a{
    color: #fff;
    text-decoration: none;
}

#website-calender td a{
    color: #00a652;
    text-decoration: none;
    font-weight: 500;
    background: none;
    border:0;
}

#website-calender .fc-h-event .fc-event-main{
    background-color: #01054e;
    padding: 3px;
    color: #fff;
    margin-bottom: 2px;
    
    border: 1px solid #01054e;
}

#website-calender .fc-daygrid-event{
    line-height: 20px;
    white-space: normal;
    border: 0;
    background: none;
}

#website-calender .fc, .fc *, .fc *:before, .fc *:after{
    font-style: normal;
}

#website-calender .fc .fc-daygrid-day.fc-day-today{
    background-color: #e3e9f5;
}
/* Popup style */
#popup .popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    z-index: 100;
    top: 0;
    left: 0;
}
   
#popup .box {
    position: relative;
    width: 50%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 3px;
    border: 1px solid #999;
    overflow: auto;
}
   
#popup .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(25% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    color: #00a652;
    font-size: 20px;
}
.testimonial .slick-dots{
    bottom: 0px;
}
.testimonial .slick-dots li button:before{
    font-size: 8px;
}
.testimonial .slick-dots li.slick-active button:before{
    color: #00a652;
    font-size: 12px;
    /* border: #00a652; */
}
.list-style-none{
    list-style: none;
}
.list-style-circle{
    list-style: circle;
    /* remove spacing to start */
    padding-left: 0;
}
.list-style-disc{
    list-style: disc;
    /* align with aprent */
    padding-left: 1.5em;

}
.list-style-square{
    list-style: square;
}
.list-style-line{
    list-style: line;
}
#new_offers .slick-list{
    padding-left: 0 !important;
}


#lead-details .nav-tabs .nav-link{
    color: #000;
    font-weight: 500;
    border: 0;
    border-bottom: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-top: 14px;
    padding-bottom: 14px;
    /* margin-right: 20px; */
    padding-left: 20px;
    padding-right: 20px;
}

#lead-details .nav-tabs .nav-link.active{
    color: #00a652;
    border-radius: 0%;
    border-bottom: 2px solid #00a652;
    background-color: #00a65211;
}

#lead-details .tox-tinymce{
    /* border: 1px solid #dae2de; */
    border-top : 0;
    border: 1px solid #dae2de;
    border-radius: 0px;
}
#activity-toshow .nav-tabs .nav-link.active{
    color: #00a652;
    border-radius: 0%;
    border-bottom: 1px solid #00a652;
    background-color: #00a65200; 
}

#activity .btn-light:focus{
    outline: none;
    box-shadow: none;
}


.css-1pahdxg-control{
    border: 1px solid #00a652;
    border-radius: 0px;
}

.coursePageImg{
    min-width:100%;
    height:480px
}

.border-end-course{
    border-right: 1px solid rgb(222, 222, 222);
}

#course-details-page .nav-custom:hover{
    color: #00a652 !important;
}

.accordion-button:focus{
    outline: none;
    box-shadow: none;
}

.fc .fc-daygrid-day-frame{
    min-height: 300px !important;
}

.img-100-80{
    width: 100px;
    height: 100px;
}
       
@media screen and (max-width:1199px){
    .navbar-nav .dropdown-menu{
        padding-left: 15px;
    }
  }

@media screen and (max-width: 988px) {
    .page-banner-title{
        font-size: 4vw;
        bottom: 35%;
    }
    .page-banner-title2{
        font-size: 3.5vw;
        bottom: 35%;
    }
    
    .page-banner-text{
        font-size: 2.5vw;
        bottom: 40%;
    }
    .graduation-rate-width{
        width: 100%;
    }

    .img-100-80{
        width: 80px;
        height: 80px;
    }

}
@media screen and (max-width: 788px) {
    .slick-slide-title{
        font-size: 5vw;
    }
    .slick-slide-title-desc{
        font-size: 3vw;
    }
    .page-banner-title{
        font-size: 5vw;
        bottom: 35%;
    }
    .page-banner-text{
        font-size: 3vw;
        bottom: 40%;
    }
    #page-section-title .fs-np-35{
        font-size: 1.6rem;
    }
    #page-section-title .fs-np-40{
        font-size: 1.8rem;
    }
    #page-section-title .fs-np-48{
        font-size: 2rem;
    }
    .ad_page_bg #page-section-title .fs-np-44{
        font-size: 1.97rem;
    }
    #page-section-title .fs-np-44{
        font-size: 1.9rem;
    }
    #page-section-title .fs-np-30{
        font-size: 1.4rem;
    }
    #page-section-title .fs-np-24{
        font-size: 1.2rem;
    }
    #page-section-title .fs-np-60{
        font-size: 3rem;
    }
    .page-intro-title{
        font-size: 2rem;
    }
    .border-end{
        border-right:0px solid !important
    }
    .testimonial_img_bg{
        background-image: none;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #dddddd;
        padding: 10px 15px;
        border-radius: 10px;

    }
    .testimonial_img_bg img{
        margin: 10px auto;
    }
    .testimonial_person_img{
        width: 100%;
        position: relative;
        float: left;
        margin-right: 0;
    }
    .slick-slide-btn{
        position: absolute;
        left: 11vw;
        font-size: 1vw;
        font-weight: 500;
        top: 55%;
        width: 80%;
      }
    .slick-slide-btn button {
        font-size: 2vw !important;
        font-weight: 600;
    }
    .slick-slide-btn a {
        font-size: 2vw !important;
        font-weight: 600;
    }
    .fs-np-14-mob{
        font-size: 0.8rem;
    }
    .partners-section .slick-slide-image{
        width: 90%;
    }
    #new_offers .slick-list{
        font-size: 12px;
        /* padding:0 !important; */
    }
       
      #popup .box {
        position: relative;
        width: 90%;
        max-height: 100vh;
        margin-top: calc(100vh - 70vh - 20px);
      }
       
      #popup .close-icon {
        right: calc(10% - 30px);
        top: calc(100vh - 70vh - 33px);
       
      }
    #website-calender .fc-toolbar{
        font-size: 2vw;
    }
    .coursePageImg{
        min-width:100%;
        height:100%
    }
    .border-end-course{
        border-right: 0px solid rgb(222, 222, 222);
    }
    #search-overlay .overlay-content{
        top: 15%;
    }

    .fc .fc-daygrid-day-frame{
        min-height: 100px !important;
    }

    .about-section-3-height{
       height: unset;
    }
    .funding-option{
        font-size: 28px !important;
    }
    .ad-course-title-lh{
        min-height: unset;
    }

}


@media screen and (max-width: 658px) {
    
    .slick-slide-title{
        width: 97%;
        left: 3vw;
        font-size: 4.5vw;
    }
    .slick-slide-title-desc{
        width: 97%;
        font-size: 3vw;
        left: 3vw;
    }
    .slick-slide-btn{
        left: 6vw;
        width: 90%;
      
    }
    .page-banner-title{
        font-size: 5vw;
        bottom: 40%;
    }
    .page-banner-title2{
        font-size: 3.3vw;
        bottom: 50%;
    }
    .page-banner-text{
        font-size: 3.5vw;
        bottom: 45%;
    }
    .core-values .fs-np-20{
        font-size: 3vw;
    }
    .core-values .fs-np-30{
        padding-top: 3px;
        font-size: 4.3vw;
    }
}
/* if max-width is less then 400 */

@media screen and (max-width: 470px) {
    .slick-slide-title{
        font-size: 5.5vw;
    }
    .slick-slide-title-desc{
        font-size: 3.4vw;
    }
    .international-students .page-banner-title{
        font-size: 4.5vw !important;
        bottom: 50%;
    }
    .cojp-page .page-banner-title{
        font-size: 4.5vw !important;
        bottom: 50%;
    }

    .page-banner-title{
        font-size: 5.5vw;
        bottom: 50%;
    }
    
    .page-banner-text{
        font-size: 4.2vw;
        bottom: 55%;
        line-height: normal;

    }

}

@media screen and (max-width: 400px) {
    .slick-slide-title{
        font-size: 5vw;
    }
    .slick-slide-title-desc{
        font-size: 3vw;
    }
    .international-students .page-banner-title{
        font-size: 4.5vw !important;
        bottom: 50%;
    }
    .cojp-page .page-banner-title{
        font-size: 4.3vw !important;
        bottom: 50%;
    }

    .page-banner-title{
        font-size: 5.5vw;
        bottom: 45%;

    }
    .page-banner-text{
        font-size: 3.4vw;
        bottom: 40%;
        line-height: normal;
        padding-top: 6px;

    }
   

}
@media screen and (max-width: 790px) {
    .cojp-page .page-banner-title{
        font-size: 4.5vw !important;
        bottom: 50%;
    }
    .width-np-65{
        width: 100%;
    }
    
}

