.table {
    font-size: 1em;
}
.style2-table thead {
    background: none !important;
}
.style2-table thead tr th:first-child {
    border-top-left-radius: 10px;
    
}
.style2-table thead tr th:first-child {
    border-top-left-radius: 10px;
    /* border-radius: 10px !important; */
}

.table thead tr th {
    padding-top: 12px !important;
    padding-bottom: 10px !important;
}
.style2-table thead tr th {
    background-color: #f5f5f5 !important;
}
.table thead th {
    text-transform: uppercase;
    font-size: 0.7rem;
}

.fb-loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffffd2;
    z-index: 9999;
    color: #000;
    height: 100%;
    /* content to full center */
    display: flex;
    justify-content: center;
	/* cover the entire screen */
	position: fixed;
	width: 100%;
	height: 100%;
    align-items: center;
    /* box-shadow: 0px 0px 4px 0px #827878a6; */
}
.ad-account-card{
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #ccc;

}
.ad-account-card:hover, .ad-account-card:focus{
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 4px 0px #827878a6;
}

.dashboard-cards {
	position: relative;
	padding-bottom: 50px;
	margin: 0 !important;
}
.dashboard-cards .card {
	background: #ffffff;
	display: inline-block;
	-webkit-perspective: 1000;
	perspective: 1000;
	z-index: 20;
	padding: 0 !important;
	margin: 5px 5px 10px 5px;
	position: relative;
	text-align: left;
	-webkit-transition: all 0.3s 0s ease-in;
	transition: all 0.3s 0s ease-in;
	z-index: 1;
	width: calc(33.33333333% - 10px);
	cursor: pointer;
	transition: all 0.3s ease;
}
.dashboard-cards .card:hover {
	box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
	transition: all 0.3s ease;
}
.dashboard-cards .card .card-title {
	background: #ffffff;
	padding: 20px 15px;
	position: relative;
	z-index: 0;
}

.dashboard-cards .card .card-title h2 {
    width: 90%;
	font-size: 18px;
	letter-spacing: -0.05em;
    font-weight: 500;
	margin: 0;
	padding: 0;
}
.dashboard-cards .card .card-title small {
	display: block;
	font-size: 14px;
	margin-top: 8px;
	letter-spacing: -0.025em;
}
.dashboard-cards .card .card-description {
	position: relative;
	font-size: 14px;
	border-top: 1px solid #ddd;
	padding: 10px 15px 0 15px;
}
.dashboard-cards .card .card-actions {
	box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.075);
	padding: 10px;
	text-align: center;
}
.dashboard-cards .card .task-count {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 22px;
	right: 10px;
	background: #ecf0f1;
	text-align: center;
	line-height: 40px;
	border-radius: 100%;
	color: #333333;
	font-weight: 600;
	transition: all 0.2s ease;
}

.dashboard-cards .card-inner {
  display: flex;
  flex-direction: column;
}
/* .sidebar{
    background-color: #01054e !important;
}

.sidebar-nav .nav-link.active {
    background: rgb(255 255 255 / 31%) !important;
} */

.stepper .active {
	background-color: #03a84e !important;
	color: #fff !important;
}

.stepper .completed {
	background-color: #03a84e !important;
	color: #fff !important;
}
